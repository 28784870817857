<template>
  <div>
    <loading-spinner v-if="isMounting"></loading-spinner>
    <div v-else class="main-profile pa-5">
      <div v-if="user" class="content-profile">
        <text-field
          id="name"
          v-model="user.name"
          :value="user.name"
          label="Nome"
          placeholder="Nome"
          required
          type="text"
        />
        <custom-select
          :default="gender"
          :label="'Gênero'"
          :option-builder="buildGenderOption"
          :options="genderOptions"
          :required="true"
          :isGender="true"
          @input="onGenderChanged"
        />
        <text-field
          id="cpf"
          v-model="user.cpf"
          v-mask="'###.###.###-##'"
          :textFieldError="isValidCPF ? `` : `CPF inválido`"
          :value="user.cpf"
          label="CPF"
          placeholder="000.000.000 - 00"
          required
          type="tel"
        />
        <text-field
          id="date_nasc"
          v-model="user.birth_date"
          :value="user.birth_date"
          label="Data de nascimento"
          placeholder="Nome"
          required
          type="date"
        />

        <text-field
          id="area_actuation"
          v-model="user.profession"
          :value="user.profession"
          label="Área de atuação"
          placeholder="Área de atuação"
          required
          type="text"
        />

        <custom-select
          :default="familyIncome"
          :label="'Renda mensal'"
          :option-builder="buildFamilyIncomeOption"
          :options="incomeOptions"
          :required="true"
          @input="onFamilyIncomeChanged"
        />

        <text-field
          id="tel"
          v-model="user.phone"
          v-mask="'(##) ##### - ####'"
          :textFieldError="isValidPhone ? `` : `Telefone Inválido`"
          :value="user.phone"
          label="Celular"
          placeholder="Telefone"
          required
          type="tel"
        />

        <text-field-mask
          id="name"
          label="CEP"
          v-model="zipcode"
          type="tel"
          mask="##### - ###"
          :value="zipcode"
          @search="searchAddress"
          placeholder="CEP"
          :textFieldError="errorCep"
          ><p>
            <a
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
            >
              Não sabe seu CEP? Clique aqui
            </a>
          </p>
        </text-field-mask>
        <div class="footer-address">
          <custom-button
            :loading="isLoading"
            :disabled="!isValidSubmitForm"
            class="mt-10 button"
            icon
            text="Confirmar"
            @click="handleUser()"
          >
            Confirmar
            <div slot="icon">
              <span class="material-icons material-icons-outlined">
                arrow_forward
              </span>
            </div>
          </custom-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/api/services/user.js";
import TextField from "@/components/Form/TextField/";
import { mask } from "vue-the-mask";
import CustomButton from "@/components/Button/";
import Loading from "@/components/Loading/Loading.vue";
import CustomSelect from "@/components/Form/CustomSelect/index.vue";
import searchs from "@/api/services/searchs";
import TextFieldMask from "@/components/Form/TextFieldMask/index.vue";
import { apiCep, apiIBGE } from "@/api/config";
import { cpf } from "cpf-cnpj-validator";

export default {
  directives: { mask },

  components: {
    TextFieldMask,
    TextField,
    "loading-spinner": Loading,
    "custom-button": CustomButton,
    "custom-select": CustomSelect,
  },

  mounted() {
    this.loadData();
    this.getUserAddress();
  },

  data() {
    return {
      user: null,
      isValidCPF: true,
      isValidPhone: true,
      isLoading: false,
      isMounting: false,
      familyIncome: null,
      gender: null,
      incomeOptions: [],
      genderOptions: [],
      zipcode: null,
      state: null,
      city: null,
      region: null,
      errorCep: null,
      loading: false,
      method: "PUT",
      message: null,
      title: null,
      validateAddress: true,
    };
  },
  watch: {
    user: {
      handler(newValue) {
        const newCPF = newValue.cpf;
        if (newCPF && newCPF.length === 14) {
          this.isValidCPF = cpf.isValid(newCPF);
        } else this.isValidCPF = false;
      },
      deep: true,
    },
  },
  computed: {
    isValidSubmitForm() {
      return (
        this.user.name &&
        this.gender &&
        this.user.birth_date &&
        this.user.cpf &&
        this.user.profession &&
        this.user.phone &&
        this.isValidCPF &&
        this.familyIncome
      );
    },
  },
  methods: {
    async getUserAddress() {
      try {
        this.loading = true;

        const response = await UserService.getAddress();
        const address = response.data.address;
        this.zipcode = address.zipcode;
      } catch (error) {
        this.validateAddress = false;

        this.method = "POST";
      } finally {
        this.loading = false;
      }
    },
    async handleAddressUser() {
      try {
        await UserService.sendToAddress(
          {
            zipcode: this.zipcode,
            city: this.city,
            state: this.state,
            region: this.region,
          },
          this.method
        );
        this.message = `Endereço atualizado com sucesso!`;
        this.title = "Tudo certo!";

        this.timeoutMessage();
      } catch (error) {
        this.validateAddress = false;
      }
    },
    async getRegion(uf) {
      const response = await apiIBGE({
        url: `/${uf}/regioes-intermediarias`,
      });
      const regionObject = response.data.find(
        (element) => element.UF.sigla === uf
      );
      this.region = regionObject.UF.regiao.nome;
    },
    async searchAddress() {
      const response = await apiCep({
        url: `/${this.zipcode.replace(/\D/g, "")}/json`,
        method: "GET",
      });
      if (response.data.erro) {
        this.city = null;
        this.state = null;
        this.errorCep = "CEP não existe";
      } else {
        const { localidade, uf } = response.data;
        this.city = localidade;
        this.state = uf;
        await this.getRegion(uf);
        this.errorCep = null;
      }
    },
    onFamilyIncomeChanged(option) {
      this.familyIncome = option;
    },
    onGenderChanged(option) {
      this.gender = option;
    },
    buildFamilyIncomeOption(option) {
      return option?.income ?? "Selecione a renda mensal";
    },
    buildGenderOption(option) {
      return option?.gender ?? "Selecione seu gênero";
    },
    getIncomeById(familyIncomeId) {
      if (!familyIncomeId) return null;

      const foundFamilyIncome = this.incomeOptions.find(
        (option) => option.id === familyIncomeId
      );

      if (!foundFamilyIncome) return null;

      return foundFamilyIncome;
    },
    getGenderById(genderId) {
      if (!genderId) return null;

      const foundGender = this.genderOptions.find(
        (option) => option.id === genderId
      );

      if (!foundGender) return null;

      return foundGender;
    },
    async loadData() {
      this.isMounting = true;

      try {
        const [userDataResponse, familyIncomeResponse, genderResponse] =
          await Promise.all([
            UserService.getUserData(),
            searchs.getFamilyIncomes(),
            searchs.getGenders(),
          ]);

        this.user = userDataResponse.data.userData;
        this.user.birth_date = this.user.birth_date.split("T")[0];
        this.incomeOptions = familyIncomeResponse.data.familyIncomes;
        this.genderOptions = genderResponse.data.genders;
        this.familyIncome = this.getIncomeById(this.user.family_income_id);
        this.gender = this.getGenderById(this.user.gender_id);
      } catch (error) {
        return error;
      }

      this.isMounting = false;
    },
    async handleUser() {
      this.isLoading = true;
      this.user.family_income_id = this.familyIncome.id;
      this.user.gender_id = this?.gender?.id;
      try {
        await UserService.updateUserData(this.user);
        if ([this.city, this.state, this.region, this.zipcode].every(Boolean)) {
          await this.handleAddressUser();
        }
        this.$toast.success("Seus dados foram atualizados com sucesso!");
      } catch (error) {
        this.$toast.error("Houve uma falha ao tentar salvar os seus dados!");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.main-profile {
  background-color: #fafafa;
  border-radius: 4px;
  margin: 1em;
}
.content-profile {
  min-width: 200px;
  max-width: 400px;
}
.pa-5 {
  padding: 2em;
}
.button {
  margin-top: 2em;
}
</style>
